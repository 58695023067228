<template>
  <div
    class="rounded-md"
    :class="[
      isCompact ? 'p-2' : 'p-3',
      accentBg ? theme.accentBackgroundClass : theme.backgroundClass,
    ]"
  >
    <div class="flex">
      <div class="flex-shrink-0">
        <SvgIcon
          :name="theme.iconTitle"
          type="solid"
          class="h-5 w-5"
          :class="theme.iconTitleClass"
          aria-hidden="true"
        />
      </div>
      <div class="flex flex-col space-y-2" :class="isCompact ? 'ml-2' : 'ml-3'">
        <h3 v-if="title" class="text-sm font-medium" :class="theme.titleClass">
          {{ title }}
        </h3>
        <div v-if="message" class="text-sm" :class="theme.messageClass">
          <p>
            {{ message }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  iconTitle: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
  message: {
    type: String,
    default: null,
  },
  theme: {
    type: String,
    default: 'success',
  },
  isCompact: {
    type: Boolean,
    default: false,
  },
  accentBg: {
    type: Boolean,
    default: false,
  },
})

const themes = [
  {
    name: 'error',
    iconTitle: 'x-circle',
    accentBackgroundClass: 'bg-alert-error-accent-background',
    backgroundClass: 'bg-alert-error-background',
    iconTitleClass: 'text-alert-error-icon',
    titleClass: 'text-alert-error-title',
    messageClass: 'text-alert-error-message',
    borderClass: 'border-alert-error-icon',
  },
  {
    name: 'success',
    iconTitle: 'check-circle',
    accentBackgroundClass: 'bg-alert-success-accent-background',
    backgroundClass: 'bg-alert-success-background',
    iconTitleClass: 'text-alert-success-icon',
    titleClass: 'text-alert-success-title',
    messageClass: 'text-alert-success-message',
    borderClass: 'border-alert-success-icon',
  },
  {
    name: 'warning',
    iconTitle: 'exclamation-circle',
    accentBackgroundClass: 'bg-alert-warning-accent-background',
    backgroundClass: 'bg-alert-warning-background',
    iconTitleClass: 'text-alert-warning-icon',
    titleClass: 'text-alert-warning-title',
    messageClass: 'text-alert-warning-message',
    borderClass: 'border-alert-warning-icon',
  },
  {
    name: 'information',
    iconTitle: 'information-circle',
    accentBackgroundClass: 'bg-alert-information-accent-background',
    backgroundClass: 'bg-alert-information-background',
    iconTitleClass: 'text-alert-information-icon',
    titleClass: 'text-alert-information-title',
    messageClass: 'text-alert-information-message',
    borderClass: 'border-alert-information-icon',
  },
]

const theme = computed(() => {
  return themes.find((theme) => theme.name === props.theme)
})
</script>
